<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";

// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "เพิ่มข้อมูลช่องทางการชำระเงิน",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      // tableData: tableData,

      title: "เพิ่มข้อมูลช่องทางการชำระเงิน",
      items: [
        {
          text: "ข้อมูลหลัก",
          active: true,
        },
        {
          text: "ศูนย์บริการ",
          active: true,
        },
        {
          text: "ช่องทางการชำระเงิน",
          active: false,
          href: "/payment-methods",
        },
        {
          text: "เพิ่มข้อมูลช่องทางการชำระเงิน",
          active: true,
        },
      ],
      selected: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      branchMaster: [],
      totalRecords: 0,
      isHidden: false,
      payment: "",
      type: "",
      roId: "",
      serviceRo: [],
      // selectMode: "single",
      userBranchId: "",
      branchId: "",
      //   payMethCode: "",
      nameTh: "",
      nameEn: "",
      payMethod: "",
      submitform: false,
      errormessage: "กรุณากรอกข้อมูลให้ครบ",
      payMethCode: [
        {
          id: 1,
          type: "C",
          payName: "CASH",
        },
        {
          id: 2,
          type: "CC",
          payName: "CREDIT CARD",
        },
        {
          id: 3,
          type: "DC",
          payName: "DEBIT CARD",
        },
        {
          id: 4,
          type: "M",
          payName: "MOBILE",
        },
        {
          id: 5,
          type: "BT",
          payName: "BANK TRANSFER",
        },
      ],
      userBranch: "",
      filterOn: [],
      rows: [],
      sortBy: "age",
      sortDesc: false,
      overlayFlag: false,
    };
  },
  validations: {
    branchId: {
      required,
    },
    nameTh: {
      required,
    },
    payMethod: {
      required,
    },
  },

  computed: {
    /**
     * Total no. of records
     */
    // rows() {
    //   return this.rows.length;
    // },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });
    this.branchMaster = user.userAccessBranch;
    this.userBranchId = user.branchId;
    this.userBranch = arrayBranch;
    this.totalRows = this.rows.total;
  },
  created() {},
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },
    // getData: function() {
    //   this.overlayFlag = true;
    //   useNetw
    //     .get("api/tax-invoice", {
    //       params: {
    //         page: this.currentPage,
    //         perPage: this.perPage,
    //       },
    //     })
    //     .then((response) => {
    //       this.rows = response.data.data;
    //       this.rows.total = response.data.total;
    //     })
    //     .catch((err) => {
    //       Swal.fire(
    //         appConfig.swal.title.error,
    //         JSON.stringify(err.response.data.message),
    //         appConfig.swal.type.error
    //       );
    //     })
    //     .then(() => {
    //       this.overlayFlag = false;
    //       // this.isHidden = true;
    //     });
    // },
    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.$invalid != true) {
        this.submitPaymentMethod();
      }
    },
    submitPaymentMethod: function() {
      useNetw
        .post("api/payment-method/store", {
          branchId: this.branchId.branchId,
          payMethCode:
            this.payMethod.payName != null ? this.payMethod.payName : undefined,
          nameTh: this.nameTh,
          nameEn: this.nameEn != null ? this.nameEn : undefined,
          payType: this.payMethod.type,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({
            name: "payment-methods",
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
        });
    },
  },
  middleware: "authentication",
};
</script>
<style>
.modal-custom .modal-dialog {
  max-width: 95%;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                  <div class="row">
                    <div class="col-5 col-sm-6 col-md-4">
                      <div class="mb-3 position-relative">
                        <code> * </code>สาขา:
                        <multiselect
                          v-model="branchId"
                          label="nameTh"
                          :options="branchMaster"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                          :class="{
                            'is-invalid': submitform && $v.branchId.$error,
                          }"
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                        <div
                          v-if="submitform && $v.branchId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.branchId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-4">
                      <div class="mb-3 position-relative">
                        <code> * </code>ประเภท:
                        <multiselect
                          v-model="payMethod"
                          label="type"
                          :options="payMethCode"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                          :class="{
                            'is-invalid': submitform && $v.payMethod.$error,
                          }"
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                        <div
                          v-if="submitform && $v.payMethod.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.payMethod.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-7 col-sm-6 col-md-4">
                      <div class="mb-3">
                        รหัสการรับชำระ:
                        <b-form-input
                          v-model="payMethod.payName"
                          class="form-control"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3 position-relative">
                        <code> * </code>ชื่อ (ไทย):
                        <b-form-input
                          v-model="nameTh"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.nameTh.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.nameTh.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.nameTh.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-4">
                      <div class="mb-3">
                        ชื่อ (อังกฤษ):
                        <b-form-input
                          v-model="nameEn"
                          class="form-control"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-12">
                        <b-button
                          class="btn float-end m-1"
                          variant="success"
                          @click="tooltipForm"
                        >
                          บันทึก
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </Layout>
</template>
